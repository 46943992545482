import React, { useContext } from 'react';

import CurrentUser from './CurrentUser';
import SignInAndSignUp from './SignInAndSignUp';
import { UserContext } from '../providers/UserProvider';

const Authentication = ({ loading }) => {
    const userData = useContext(UserContext);
    if (loading) return null;
    return (
        <>{
            userData && userData.user ?
                <div className="header">
                    <CurrentUser {...userData} />
                </div>
                :
                <SignInAndSignUp />
        }</>
    );
};

export default Authentication;