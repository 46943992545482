import React, { lazy, Suspense } from 'react';
import Authentication from './Authentication';
import { Routes, Route } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import withUser from './withUser';
import LeadsProvider from '../providers/LeadsProvider';
import UserCookieConsent from './UserCookieConsent';

const HomePage = lazy(() => import('./HomePage'));
const PagePrivacyPolicy = lazy(() => import('./Pages/PagePrivacyPolicy'));
const PagePrivacyPolicyCookies = lazy(() => import('./Pages/PagePrivacyPolicyCookies'));
const PageTermsOfUse = lazy(() => import('./Pages/PageTermsOfUse'));
const PageInfoAgile = lazy(() => import('./Pages/PageInfoAgile'));
const Dashboard = lazy(() => import('./Dashboard'));
const PageLeads = lazy(() => import('./PageLeads'));
const UserProfile = lazy(() => import('./UserProfile'));
const AddProjectPage = lazy(() => import('./AddProjectPage'));
const ProjectPageInvoicePayment = lazy(() => import('./ProjectPages/ProjectPageInvoicePayment'));
const PageLoader = lazy(() => import('./ProjectPages/PageLoader'));

const Application = ({ user }) => {

    return (
        <main className="Application">
            <Suspense fallback={<div className="loading-spinner"><ScaleLoader /></div>
            }>
                <Routes>

                    {/* LEGAL PAGES */}
                    <Route path="/cookie-policy" element={<PagePrivacyPolicyCookies user={user} />} />
                    <Route path="/privacy" element={<PagePrivacyPolicy user={user} />} />
                    <Route path="/terms" element={<PageTermsOfUse user={user} />} />

                    {/* INFO PAGES */}
                    <Route path="/agile" element={<PageInfoAgile user={user} />} />

                    {/* PROJECT SPECIFIC PAGES */}
                    <Route path="/projects" element={<LeadsProvider><Dashboard user={user} /></LeadsProvider>} />
                    <Route path="/dashboard" element={<LeadsProvider><Dashboard user={user} /></LeadsProvider>} />
                    <Route path="/leads" element={<LeadsProvider><PageLeads user={user} /></LeadsProvider>} />
                    <Route path="/profile" element={<LeadsProvider><UserProfile user={user} /></LeadsProvider>} />
                    <Route path="/add-project" element={<AddProjectPage user={user} />} />

                    {/* Project Overview */}
                    <Route path="/project/:id" element={<PageLoader user={user} page="ProjectPage" />} />

                    {/* Discovery */}
                    <Route path="/project/:id/discovery" element={<PageLoader user={user} page="ProjectPageDiscovery" />} />

                    {/* Sitemap & Content */}
                    <Route path="/project/:id/sitemap" element={<PageLoader user={user} page="ProjectPageSitemap" />} />

                    {/* Design */}
                    <Route path="/project/:id/design" element={<PageLoader user={user} page="ProjectPageDesignsAndWireframes" />} />

                    {/* Design - Branding */}
                    <Route path="/project/:id/design/branding" element={<PageLoader user={user} page="ProjectPageDesignBranding" />} />

                    {/* Design - MoodBoard */}
                    <Route path="/project/:id/design/moodboard" element={<PageLoader user={user} page="ProjectPageMoodBoard" />} />

                    {/* Design - Website References */}
                    <Route path="/project/:id/design/references" element={<PageLoader user={user} page="ProjectPageReferences" />} />

                    {/* Design - Typography */}
                    <Route path="/project/:id/design/typography" element={<PageLoader user={user} page="ProjectPageDesignTypography" />} />

                    {/* Development */}
                    <Route path="/project/:id/development" element={<PageLoader user={user} page="ProjectPageDevelopment" />} />

                    {/* Development - Commit Log */}
                    <Route path="/project/:id/development/commitlog" element={<PageLoader user={user} page="ProjectPageDevelopmentCommitLog" />} />

                    {/* Contract */}
                    <Route path="/project/:id/contract" element={<PageLoader user={user} page="ProjectPageContract" />} />

                    {/* Billing */}
                    <Route path="/project/:id/billing" element={<PageLoader user={user} page="ProjectPageBilling" />} />

                    {/* Billing - Invoice */}
                    <Route path="/project/:id/invoice/:iid" element={<PageLoader user={user} page="ProjectPageInvoicePayment" />} />

                    {/* TimeTracking */}
                    <Route path="/project/:id/time" element={<PageLoader user={user} page="ProjectPageTimeTracking" />} />

                    {/* Meetings */}
                    <Route path="/project/:id/meetings" element={<PageLoader user={user} page="ProjectPageMeetings" />} />

                    {/* Meetings - Single Meeting */}
                    <Route path="/project/:id/meetings/:id" element={<PageLoader user={user} page="ProjectPageMeetings" />} />

                    {/* Task Board */}
                    <Route path="/project/:id/tasks" element={<PageLoader user={user} page="ProjectPageTaskBoard" />} />

                    {/* Task Board 2*/}
                    <Route path="/project/:id/tasks2" element={<PageLoader user={user} page="ProjectPageTaskBoard2" />} />



                    <Route path="/" element={<HomePage user={user} />} />
                    <Route path="/cookie-policy" element={<PagePrivacyPolicyCookies user={user} />} />
                    <Route path="/privacy" element={<PagePrivacyPolicy user={user} />} />
                    <Route path="/terms" element={<PageTermsOfUse user={user} />} />
                    <Route path="/agile" element={<PageInfoAgile user={user} />} />

                    {/* PUBLIC READ ACCESS: Billing - Invoice */}
                    {/* since invoice payments are publicly accessible, the route must be above the <Authentication /> component. */}
                    <Route path="/project/:id/invoice/:iid" element={<ProjectPageInvoicePayment user={user} />} />

                    <Route path="*" element={<HomePage user={user} />} />
                </Routes>

                <UserCookieConsent user={user} />
                <Authentication />

            </Suspense>
        </main>
    );
}

export default withUser(Application);