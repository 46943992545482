import React from 'react';
import { doc, setDoc } from "firebase/firestore";
import CookieConsent from "react-cookie-consent";
import { db } from '../firebase';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';


const UserCookieConsent = props => {
    // const [userHas]
    // useEffect(() => {

    // }, [props]);

    const doSaveUserCookies = () => {
        // ReactGA.initialize('UA-5458962-12');
        // ReactGA.pageview(window.location.pathname + window.location.search);

        if (props.user && props.user.uid) {
            const saveUserCookiesRef = doc(db, 'users', props.user.uid);
            setDoc(saveUserCookiesRef, { userAcceptedCookies: true }, { merge: true });
        }
    }

    // const userDeclinesCookies = () => {
    //     document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //     document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //     document.cookie = "_gt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //     document.cookie = "_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }


    return (
        <CookieConsent
            buttonText="Ok"
            cookieName="userAcceptedCookies"
            style={{ background: "#222", padding: "3em", fontSize: "1.5em" }}
            buttonStyle={{ background: "#008000 ", fontWeight: "bold", width: "7em", color: "#fff", borderRadius: "3px" }}
            containerClasses="cookie-consent-container"
            contentClasses="cookie-consent-content"
            buttonWrapperClasses="btn-cookie-consent"
            expires={150}
            onAccept={() => { doSaveUserCookies(); }}
        >
            <p><span style={{ fontSize: "1.5em" }}>Hi <span role="img" aria-label="wave">👋</span></span></p>
            <p>We use <Link to="/cookie-policy">cookies</Link> to improve your experience</p>
            <p><span style={{ fontSize: "0.55em" }}>By continuing you agree to our <Link to="/privacy">privacy policy</Link> and <Link to="/terms">terms of use</Link></span></p>
        </CookieConsent>
    )
}

export default UserCookieConsent;