import React, { useState, lazy, Suspense } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { signInUsingDemo } from '../firebase';
import { Navigate } from 'react-router-dom';

const Container = lazy(() => import('react-bootstrap/Container'));
const Row = lazy(() => import('react-bootstrap/Row'));
const Col = lazy(() => import('react-bootstrap/Col'));
const Card = lazy(() => import('react-bootstrap/Card'));
const Button = lazy(() => import('react-bootstrap/Button'));

const SignInViaEmailLink = lazy(() => import('./SignInViaEmailLink'));

const SignInAndSignUp = props => {

    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleLoginAsDemo = event => {
        event.preventDefault();


        signInUsingDemo().then((user) => {
            setLoginSuccessful(true);
        })
            .catch((error) => {
                const { code, message } = error;
                if (code) {
                    //console.log(code);
                }

                if (message) {
                    setErrorMessage(message);
                }
            });
    };
    return (
        <div className="signinandup">
            <Suspense fallback={<div className="loading-spinner"><ScaleLoader /></div>
            }>
                <Container>
                    <Row>
                        <Col>
                            <Card className="signin signup inup">
                                <SignInViaEmailLink />
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Card className="signin signup inup">
                                <h3>Demo User</h3>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <Button onClick={(e) => handleLoginAsDemo(e)}>Continue</Button>
                                        {errorMessage && <p className="warning">ERROR: {errorMessage}</p>}
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    {loginSuccessful && <p className="success">Success!</p> &&
                        <Navigate to='/projects' />
                    }
                </Container>
            </Suspense>
        </div>
    );
}

export default SignInAndSignUp;
