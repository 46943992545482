import React, { lazy } from 'react';

import { Link } from 'react-router-dom';

const Container = lazy(() => import('react-bootstrap/Container'));
const Row = lazy(() => import('react-bootstrap/Row'));
const Col = lazy(() => import('react-bootstrap/Col'));

const CurrentUser = ({ user, userFields }) => {

    let photoURL = '';
    let displayName = '';

    if (user && userFields) {
        photoURL = userFields.photoURL || user.photoURL; // Google account photo as fallback.
        displayName = userFields.displayName;
    }

    return (
        <section className="CurrentUser">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className="CurrentUser--information">
                            <Link to="/profile" className="user-img-link">
                                {photoURL && displayName && <img src={photoURL} alt={displayName} />}
                            </Link>

                            <Link to="/profile">
                                <p className="name">
                                    {displayName || 'Loading...'}
                                </p>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CurrentUser;