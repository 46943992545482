import React from 'react';
import { createRoot } from 'react-dom/client';

import Application from './components/Application';
import UserProvider from './providers/UserProvider';
import ProjectsProvider from './providers/ProjectsProvider';
import { BrowserRouter } from "react-router-dom";

import './bootstrap.min.css';
import './styles.scss';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-5458962-12');
// ReactGA.pageview(window.location.pathname + window.location.search);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <UserProvider>
        <ProjectsProvider>
            <BrowserRouter>
                <Application />
            </BrowserRouter>
        </ProjectsProvider>
    </UserProvider>
);