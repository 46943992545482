import React from 'react';
import { UserContext } from '../providers/UserProvider';

const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const withUser = (Component) => {
    const WrappedComponent = (props) => {
        return (
            <UserContext.Consumer>
                {(userContext) => <Component user={userContext.user} userFields={userContext.userFields} {...props} />}
            </UserContext.Consumer>
        );
    };

    WrappedComponent.displayName = `WithUser(${getDisplayName(WrappedComponent)})`;
    return WrappedComponent;
};

export default withUser;