import React, { useState, useEffect, createContext } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { collectIdsAndData } from '../utilities';
import withRouter from '../components/withRouter';
import withUser from '../components/withUser';

export const ProjectsContext = createContext();

const getInvoices = async (proj, setInvoices) => {
    const invoiceRef = collection(db, "projects", proj.id, "invoices");

    // Use onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(invoiceRef, (querySnapshot) => {
        const invoices = [];
        querySnapshot.forEach((doc) => {
            invoices.push(doc.data());
        });
        setInvoices(invoices);
    });

    return unsubscribe;
}

const ProjectsProvider = ({ user, children }) => {
    const [projects, setProjects] = useState([]);
    const [projectsShared, setProjectsShared] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            if (user && user.uid) {
                const userProjectsRef = collection(db, 'users', user.uid, 'projects');

                // Use onSnapshot to listen for real-time updates
                const unsubscribe = onSnapshot(userProjectsRef, (querySnapshot) => {
                    const projectsData = querySnapshot.docs.map(collectIdsAndData);
                    const ownedProjects = [];
                    const sharedProjects = [];

                    Promise.all(
                        projectsData.map(async proj => {
                            // Use getInvoices function to set up real-time updates for invoices
                            const invoicesUnsubscribe = getInvoices(proj, (invoices) => {
                                proj.invoices = invoices;
                            });

                            proj.invoices = [];
                            if (proj.owner === user.uid) {
                                ownedProjects.push(proj);
                            } else if (proj.owner !== user.uid) {
                                if (proj.access === 'read' || proj.access === 'full') {
                                    sharedProjects.push(proj);
                                }
                            }

                            // Cleanup function to unsubscribe from invoices updates when the project unmounts
                            return () => {
                                invoicesUnsubscribe();
                            };
                        })
                    );

                    setProjects(ownedProjects);
                    setProjectsShared(sharedProjects);
                });

                // Cleanup function to unsubscribe from userProjectsRef updates when the component unmounts
                return () => {
                    unsubscribe();
                };
            }
        };

        fetchProjects();
    }, [user]);

    return (
        <ProjectsContext.Provider value={{ projects, projectsShared }}>
            {children}
        </ProjectsContext.Provider>
    );
};

export default withRouter(withUser(ProjectsProvider));
