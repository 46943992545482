import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const provider = new GoogleAuthProvider();

export const auth = getAuth(app);
//export const myAuth = getAuth(app);
// onAuthStateChanged(auth, user => {
//     // Check for user status
// });
// export const authUID = auth.onAuthStateChanged(function (user) {
//     if (user) {
//         return user.uid
//     } else {
//         return null;
//     }
// });

export const storage = getStorage();

const email = 'demo@example.com';
const password = 'demo123';

export const signInUsingDemo = () => signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
    });



export const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
}
//signInWithPopup(provider);
export const signOut = () => auth.signOut();

// DEBUG - Disable for production 
//window.firebase = firebase;

export const createUserProfileDocument = async (user) => {
    if (!user) return;

    // Get a reference to the place in the database where a user profile might be.
    const userRef = doc(db, "users", user.uid);

    // Get a document, forcing the SDK to fetch from the offline cache.
    try {
        const doc = await getDoc(userRef);
        // Document was found in the cache. If no cached document exists,
        // an error will be returned to the 'catch' block below.
        //console.log("Cached document data:", doc.data());
        // Go and fetch the document from that location.
        let userData = doc.data();
        const createdAt = new Date();
        userData.createdAt = createdAt;

        // userRef.set({
        //     uid,
        //     displayName,
        //     email,
        //     photoURL,
        //     createdAt,
        //     ...additionalData,
        // });
        return userData;

    } catch (e) {
        console.log("Error getting cached document:", e);
    }
    return getUserDocument(user.uid);
};

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        return doc(db, "users", uid);
    } catch (error) {
        console.error('Error fetching user', error.message);
    }
}

export default app;