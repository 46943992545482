import React, { useState, useEffect, createContext } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import { collectIdsAndData } from '../utilities';
import withRouter from '../components/withRouter';
import withUser from '../components/withUser';

export const LeadsContext = createContext();

const LeadsProvider = ({ user, children }) => {
    const [leads, setLeads] = useState([]);

    useEffect(() => {
        const fetchLeads = async () => {
            if (user && user.uid) {
                const leadsCollection = await getDocs(collection(db, 'users/' + user.uid + '/leads'));
                const leadsData = leadsCollection.docs.map(collectIdsAndData);
                setLeads(leadsData);
            }
        };
        fetchLeads();
    }, [user]);

    return (
        <LeadsContext.Provider value={leads}>{children}</LeadsContext.Provider>
    );
};

export default withRouter(withUser(LeadsProvider));