import React, { useState, useEffect, createContext } from 'react';
import { db } from '../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

export const UserContext = createContext({ user: null });
const auth = getAuth();

const fetchUserData = async (uid) => {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
        const userData = userSnap.data();
        return userData;
    }
};

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userFields, setUserFields] = useState(null);

    const handleAuthStateChanged = async () => {
        if (auth && auth.currentUser) {
            setUser(auth.currentUser);

            const userRef = doc(db, 'users', auth.currentUser.uid);
            const unsubscribeUserData = onSnapshot(userRef, (userDoc) => {
                const userData = userDoc.data();
                setUserFields(userData);
            });

            return () => {
                unsubscribeUserData();
            };
        }
    };

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChanged);

        return () => {
            unsubscribeAuth();
            fetchUserData();
        };
    }, []);

    return (
        <UserContext.Provider value={{ user, userFields }}>{children}</UserContext.Provider>
    );
};

export default UserProvider;
